import { Card, CardConfig } from "./Card";
import { Platform } from "./Platform";
import { PlayerData } from "./PlayerData";
import { Result, ResultConfig } from "./Uitslag";
import { shuffle } from "./Utility";

export interface GameConfig {
  path: string;
  cards: CardConfig[];
  question: string;
  results: ResultConfig[];
  shuffleCards: boolean;
  showCardTitle: boolean;
  lives?: number;
  timeLimit?: number;
  showQuestion: boolean;
  cardLimit?: number;
  randomResult: boolean;
  resultPath: string;
  font: string;
  fontSize: number;
  fontColor: string;
  swipeSound?: string;
  rightAnswerSound?: string;
  wrongAnswerSound?: string;

  cardContainerColor: string;
  cardBorderWidth: string;
  cardBorderColor: string;
  cardBorderRadius: string;
  cardColor: string;

  showCardQuestion: boolean;

  indicatorPadding: number;
  indicatorWidth: number;
  swipeLeftIndicatorImage?: string;
  swipeRightIndicatorImage?: string;

  questionFont: string;
  questionColor: string;
  questionSize: number;

  cardQuestionFont: string;
  cardQuestionColor: string;
  cardQuestionSize: number;

  cardTitleFont: string;
  cardTitleColor: string;
  cardTitleSize: string;
}

export class SwipeGame {
  firstGame: boolean = true;
  platform: Platform;
  config: GameConfig;
  playing: boolean = false;

  gameScreen!: HTMLDivElement;

  cardContainer!: HTMLDivElement;
  questionElement!: HTMLDivElement;

  playerData: PlayerData = new PlayerData();
  cards: Card[] = [];
  lives?: number;

  timeLeft?: number;

  currentCard: number = 0;

  timeBarInterval: any;

  constructor(config: GameConfig) {
    this.platform = new Platform();
    this.config = config;
    this.init();
  }

  getElement(name: string) {
    return document.getElementById(name) as HTMLDivElement;
  }

  init() {
    document.body.style.fontSize = this.config.fontSize + "px";
    document.body.style.color = this.config.fontColor;
    document.body.style.fontFamily = this.config.font;

    this.gameScreen = this.getElement("game-screen");

    this.cardContainer = this.getElement("card-container");

    this.cardContainer.style.backgroundColor = this.config.cardContainerColor;

    this.cardContainer.style.borderRadius = this.config.cardBorderRadius + "px";

    this.questionElement = this.getElement("question");

    this.questionElement.style.color = this.config.questionColor;
    this.questionElement.style.fontFamily = this.config.questionFont;
    this.questionElement.style.fontSize = this.config.questionSize + "px";

    if (!this.config.showQuestion) {
      this.questionElement.remove();
    }

    this.restart();

    this.platform.init(this, this.config);
    this.platform.preloadSounds(this.config);
  }

  nextQuestion() {
    const card = this.cards[this.currentCard];

    card.card.style.zIndex = "5";

    card.card.onpointerdown = (e) => {
      if (!this.playing) {
        return;
      }
      card.dragStart = e.pageX;
      card.card.classList.remove("hover:cursor-grab");
      card.card.classList.add("cursor-grabbing");
    };

    card.card.onpointerup = (e) => {
      if (!this.playing) {
        return;
      }
      if (card.dragStart) {
        card.release();
      }
    };

    document.body.onpointerleave = (e) => {
      if (!this.playing) {
        return;
      }
      if (card.dragStart) {
        card.rotation =
          card.rotation > 0
            ? card.maxRotation / 2 + 1
            : -card.maxRotation / 2 - 1;

        card.release();
      }
    };

    card.card.onpointermove = (e) => {
      e.preventDefault();

      if (!this.playing) {
        return;
      }
      if (!card.dragStart) {
        return;
      }
      const dragDelta = e.pageX - card.dragStart;

      card.rotation = Math.round(dragDelta / 10);

      if (card.rotation > 0 && card.rotation > card.maxRotation) {
        card.rotation = card.maxRotation;
      } else if (card.rotation < 0 && card.rotation < -card.maxRotation) {
        card.rotation = -card.maxRotation;
      }

      card.card.style.transform = `translateX(${dragDelta}px) rotate(${card.rotation}deg)`;

      card.setIndicatorStyle();
    };

    if (
      this.currentCard > 0 &&
      this.currentCard + 1 <
        (this.config.cardLimit && this.config.cardLimit !== 0
          ? Math.min(this.config.cardLimit, this.config.cards.length)
          : this.config.cards.length)
    ) {
      this.cards.push(
        new Card(
          this,
          this.config.cards[this.currentCard + 1],
          () => this.onSwipe(),
          this.currentCard + 1
        )
      );
    }

    if (!this.config.showQuestion) {
      return;
    }

    this.setQuestionElement(card.config);
  }

  onSwipe() {
    this.platform.playSound(this.config.swipeSound);

    this.currentCard += 1;

    this.setProgressBarWidth();

    if (
      this.currentCard >=
      (this.config.cardLimit && this.config.cardLimit !== 0
        ? Math.min(this.config.cardLimit, this.config.cards.length)
        : this.config.cards.length)
    ) {
      this.goToResult();
    } else {
      this.nextQuestion();
    }
  }

  play() {
    if (this.playing) {
      return;
    }
    this.playing = true;
    if (this.timeLeft) {
      this.timeBarInterval = setInterval(() => {
        this.timePassed(50);
      }, 50);
    }
    this.platform.gamestarted();
  }

  loseLife() {
    // this.platform.playSound(this.config.wrongAnswerSound);
    // if (!this.lives) return;
    // this.lives -= 1;
    // this.platform.sendLives(this.lives);
    // if (this.lives === 0) {
    //   this.goToResult();
    // }
  }

  goToResult() {
    this.firstGame = false;
    this.playing = false;

    clearInterval(this.timeBarInterval);

    if (this.config.results.length === 0) {
      this.platform.gameover(
        this.playerData.score,
        "",
        JSON.stringify(this.playerData)
      );
      return;
    }

    const result = new Result(this, this.playerData.score, this.config.results);

    this.playerData.uitslag = result.toString();

    // this.playerData.uitslag = result.config

    this.platform.gameover(
      this.playerData.score,
      result.config,
      JSON.stringify(this.playerData)
    );
  }

  questionHeight: number = 0;

  restart() {
    this.questionHeight = 0;
    this.cards.forEach((card) => {
      card.destroy();
    });
    this.cards = [];

    this.playerData = new PlayerData();

    this.lives = this.config.lives;

    if (this.lives) this.platform.sendLives(this.lives);

    this.timeLeft = this.config.timeLimit;

    if (this.config.shuffleCards) {
      this.config.cards = shuffle(this.config.cards);
    }

    this.toggleVisible(this.gameScreen, true);

    for (let i = 0; i < this.config.cards.length; i++) {
      if (this.config.cardLimit && i >= this.config.cardLimit) {
        break;
      }
      const card = this.config.cards[i];

      if (i < 2) this.cards.push(new Card(this, card, () => this.onSwipe(), i));

      if (this.config.showQuestion)
        this.questionHeight = Math.max(
          this.questionHeight,
          this.setQuestionElement(card)
        );
    }

    this.questionElement.style.height = `${this.questionHeight}px`;

    this.currentCard = 0;

    this.setProgressBarWidth();

    this.nextQuestion();

    this.platform.ready();
  }

  setQuestionElement(card: CardConfig) {
    const question = this.config.question;

    const cardQuestionStyle = `color: ${
      this.config.cardQuestionColor
    }; font-family: ${this.config.cardQuestionFont || "inherit"}; font-size: ${
      this.config.cardQuestionSize
    }px; margin-top: ${this.config.cardQuestionSize}px;`;

    let cardQuestion = "";
    if (this.config.showCardQuestion && card.question) {
      cardQuestion = card.question;
    }
    this.questionElement.innerHTML = `<span>${question.trim()}</span>${
      cardQuestion && cardQuestion.length > 0
        ? `<span style="${cardQuestionStyle}">${cardQuestion.trim()}`
        : ""
    } </span>`;

    return this.questionElement.clientHeight;
  }

  setProgressBarWidth() {
    const width = Math.round(
      ((this.currentCard + 1) /
        ((this.config.cardLimit && this.config.cardLimit !== 0
          ? Math.min(this.config.cardLimit, this.config.cards.length)
          : this.config.cards.length) +
          1)) *
        100
    );
    this.platform.sendProgress(width);
  }

  timePassed(time: number) {
    if (!this.timeLeft || !this.config.timeLimit) {
      return;
    }
    this.timeLeft = this.timeLeft - time;
    this.platform.sendTimeleft((this.timeLeft / this.config.timeLimit) * 100);

    if (this.timeLeft <= 0) {
      this.goToResult();
    }

    // this.timeBarElement.style.width = `${
    // 	(this.timeLeft / this.config.timeLimit) * 100
    // }%`;
  }

  toggleVisible(element: HTMLDivElement, visible: boolean) {
    if (!element) {
      return;
    }
    element.style.opacity = visible ? "100%" : "0";
    element.style.pointerEvents = visible ? "all" : "none";
  }

  addPoints(points: number) {
    this.platform.playSound(this.config.rightAnswerSound);

    this.playerData.score += points;

    this.platform.sendScore(this.playerData.score);
    // this.scoreElement.innerHTML = this.playerData.score
    // 	.toString()
    // 	.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
